<template>
  <v-overlay :value="loading" :style="loadingStyle">
    <v-row>
      <v-col justing="center" align="center">
        <v-progress-circular indeterminate size="80"></v-progress-circular>
      </v-col>
    </v-row>

    <div class="text-center">
      <div class="mt-8 mb-4 text-h5" v-if="title">
        {{ title }}
      </div>

      <div v-if="description" class="subtitle-1 font-weight-light">
        {{description}}
      </div>
    </div>
  </v-overlay>
</template>

<script lang="babel" type="text/babel">
import {mapGetters} from 'vuex'
export default {
  computed: {
    ...mapGetters({
      loading: 'loading/status',
      title: 'loading/title',
      description: 'loading/description',
    }),
    loadingStyle() {
      return {
        zIndex: window.$zIndexConfig.loading,
      }
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>